import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { Meeting } from 'src/app/Models/meeting/Meeting';
import { AgendaItem } from 'src/app/Models/meeting/AgendaItem';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { User } from 'src/app/models/user/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessagesService } from 'src/app/services/messages.service';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import {CountdownComponent} from 'ngx-countdown';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-agenda-list',
  templateUrl: './agenda-list.component.html',
  styles: []
})
export class AgendaListComponent implements OnInit {

  @Input() meeting: Meeting;
  @Input() isOpen: boolean;
  @Input() liveView: boolean;
  itemsLoading = false;
  openItemModal = false;
  addAgendaItem = false;
  replyLoader = false;


  agendaData: any = {
    pageData: []
  };
  agendItems: AgendaItem[] = [];
  selectedAgendaItem: AgendaItem;

  commentLoader = false;
  myComment = '';
  @ViewChild('comment', {static: false}) commentElement: ElementRef;
  currentUser: User = {
    name: '',
  } as User;
  addComentobj = {
    GroupId: 0,
    CommentContent: '',
    CurrentCollectionGroupingId: null,
    EntityId: 0,
    ObjectType: '',
    CreatedAsId: null,
    CreatedAsCollectionId: null
  };
  commentAs = {
    collectionEntityId: 0,
    currentUser: false,
    email: '',
    fullName: '',
    groupUserCollectionId: 0,
    collectionsGroupingId: 0,
    profilePhoto: '',
    userId: '',
    userName: '',
  };
  showCommentPanel = undefined;
  showEditPanel = undefined;

  // voting
  loading = false;
  showvotingModal = false;
  selectedOption: string;
  questionTitle = '';
  questionOptions: any;
  questionAnswer = '';
  noAnswer = false;
  noSelected = false;
  questionType = null;
  votingAgendaItem: AgendaItem;
  addVoteObj = {
    agendaItemEntityId: null,
    VotingSchemaOptionId: null,
    VotingSchemasAnswer: '',
    CreatedAsId: null
  };
  voteAs = {
    currentUser: false,
    email: '',
    fullName: '',
    profilePhoto: '',
    userId: '',
    userName: '',
  };

  // time
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  isShow = false;
  constructor(
    public zone: NgZone,
    private translate: TranslateService,
    private messagesService: MessagesService,
    private authenticationService: AuthenticationService,
    private detailsService: MeetingDetailsService,
    private signalRService: SignalRServiceService
  ) {
    this.signalRService.buildConnection();
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.listAgendItems();
    this.signalRService.handleStartVotingEvent(this, this.showVoteAnswerDialog);
    this.signalRService.handleEndVotingEvent(this, this.hideVoteAnswerDialog);
    this.signalRService.handleNewDelegationEvent(this, this.refreshAgendaList);
    this.signalRService.handleNewRecommendationEvent(this, this.refreshAgendaList);

    // startVoting
    this.detailsService.startVoting.subscribe(data => {
      if (data) {
        this.isShow = true;
      }else{
        this.isShow = false;
      }
    });
  }
  showVoteAnswerDialog(context, agendaItemId, votingTime) {
    context.zone.run(() => {
      context.agendItems.forEach(item => {
        if (item.agendaItemEntityId === agendaItemId) {
          context.listAgendItems();
          // context.changeDetectorRef.detectChanges();
        }
      });
    });
  }
  hideVoteAnswerDialog(context, agendaItemId, votingTime) {
    context.zone.run(() => {
      context.agendItems.forEach(item => {
        if (item.agendaItemEntityId === agendaItemId) {
          context.closePop();
          // context.changeDetectorRef.detectChanges();
        }
      });
    });
  }
  refreshAgendaList(context, meetingId) {
    context.zone.run(() => {
      if (meetingId === context.meeting.meetingId) {
        context.listAgendItems();
      }
    });
  }
  listAgendItems(isopen = false) {
    this.detailsService.startVoting.next(false);
    const obj = {
      id: this.meeting.meetingId,
      PageNumber: 0,
      PageSize: 100
    };
    this.itemsLoading = true;
    this.detailsService.ListAgendItems(obj).subscribe(
      result => {
        this.itemsLoading = false;
        this.agendaData = result as {};
        this.agendItems = this.agendaData.pageData as AgendaItem[];

        // Force open vote
        this.openCurrentVote(isopen);
        console.log('agendItems', this.agendItems);
        // this.openAgendaItem(this.agendItems[2]); /// comment
      },
      err => {
        this.itemsLoading = false;
        console.error(err);
      }
    );
  }

  openCurrentVote(isopen = false) {
    this.detailsService.startVoting.next(false);
    this.agendItems.forEach(item => {
      if (!this.meeting.isOwner && item.allowedVoting && item.voteAsWho.length) {
        this.openVotingModal(item);
      }
      if (this.meeting.isOwner && this.agendaData.canViewDetails && item.allowedVoting && this.liveView && isopen) {
        this.openAgendaItem(item);
      }
      if (this.meeting.isOwner && this.agendaData.canViewDetails && item.allowedVoting && this.liveView) {
        this.detailsService.startVoting.next(true);
      }
    });
  }
  closeGfendaItem(){
    this.openItemModal = false;
  }
  openAgendaItem(item) {
    console.log(item);
    this.openItemModal = true;
    this.selectedAgendaItem = item;
    this.selectedAgendaItem.itemsCount = this.agendItems.length;
  }
  saveItemSucsses() {
    this.addAgendaItem = false;
    Swal.fire({
      title: this.translate.instant('swalMsg.addedsuccessfully'),
      text: '',
      icon: 'success',
      confirmButtonText: this.translate.instant('swalMsg.ok'),
    }).then((result) => {
      this.listAgendItems();
    });
  }
  textAreaAdjust(e) {
    let target = e.target;
    if (target === undefined) { target = e; }
    target.style.height = '22px';
    target.style.height = target.scrollHeight + 'px';
  }
  trim(value) { return value.trim(); }
  showCommentArea(i) {
    this.myComment = '';
    this.showCommentPanel = i;
    this.commentAs = this.agendItems[i].commentAsWho[0];
    setTimeout(() => {
      this.commentElement.nativeElement.focus();
      this.textAreaAdjust(this.commentElement.nativeElement); }, 0);
  }
  showEditArea(commentId) {
    if (this.showEditPanel === undefined || this.showEditPanel !== commentId) {
      this.showEditPanel = commentId;
    } else {
      this.showEditPanel = undefined;
    }
  }
  addMyComment(agendaItemEntityId) {
    this.commentLoader = true;
    if (this.myComment) {
      this.addComentobj.CommentContent = this.myComment;
      this.addComentobj.GroupId = this.meeting.groupDetails.groupId;
      this.addComentobj.EntityId = agendaItemEntityId;
      this.addComentobj.ObjectType = 'MeetingAgendaItemsEntity';
      this.addComentobj.CurrentCollectionGroupingId = this.commentAs.collectionsGroupingId;
      if (!this.commentAs.currentUser) {
        this.addComentobj.CreatedAsId = this.commentAs.userId;
        this.addComentobj.CreatedAsCollectionId = this.commentAs.collectionEntityId;
      } else {
        this.addComentobj.CreatedAsId = null;
        this.addComentobj.CreatedAsCollectionId = null;
      }
      this.messagesService.AddComment(this.addComentobj).subscribe(
        result => {
          this.myComment = '';
          this.showCommentPanel = undefined;
          this.commentLoader = false;
          this.listAgendItems();
        }, err => {
          this.commentLoader = false;
          console.error(err);
        });
    }
  }

  editComment(comment) {
    this.replyLoader = true;
    const editObj = {
      Id: comment.commentId,
      Content: comment.content,
      ObjectType: 'MeetingAgendaItemsEntity'
    };
    this.messagesService.EditComment(editObj).subscribe(
      result => {
        this.replyLoader = false;
        this.showEditPanel = undefined;
        this.listAgendItems();
      }, err => {
        console.error(err);
      });
  }
  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }


  openVotingModal(item) {
    this.showvotingModal = true;
    if (item.votingModel) {
      this.votingAgendaItem = item;
      this.questionType = item.votingModel.votingSchemaType;
      // this.questionOptions = item.votingModel.votingOptions;
      this.questionOptions = item.votingModel.votingOptionsList;
      this.questionTitle = item.votingModel.title;
      this.voteAs = this.votingAgendaItem.voteAsWho[0];
    }
    this.addVoteObj.agendaItemEntityId = item.agendaItemEntityId;
    // this.addVoteObj.VotingSchemaOptionId =
    // console.log(this.questionType);
  }
  closePop() {
    this.showvotingModal = false;
    this.loading = false;
    this.noSelected = false;
    this.noAnswer = false;
    this.questionAnswer = '';
    this.selectedOption = '';
    this.listAgendItems();
  }
  saveVotingItem() {
    if (this.questionType === 1 || this.questionType === 2) {
      if (this.selectedOption) {
        this.noSelected = false;
        this.loading = true;
        this.addVoteObj.VotingSchemasAnswer = '';
        this.addVoteObj.VotingSchemaOptionId = this.selectedOption;
      } else {
        this.loading = false;
        this.noSelected = true;
      }
    }
    if (this.questionType === 0) {
      if (this.questionAnswer) {
        this.noAnswer = false;
        this.loading = true;
        this.addVoteObj.VotingSchemaOptionId = '';
        this.addVoteObj.VotingSchemasAnswer = this.questionAnswer;
      } else {
        this.loading = false;
        this.noAnswer = true;
      }
    }
    if (!this.voteAs.currentUser) {
      this.addVoteObj.CreatedAsId = this.voteAs.userId;
    } else {
      this.addVoteObj.CreatedAsId = null;
    }
    if (((this.questionType === 1  || this.questionType === 2) && this.selectedOption) || (this.questionType === 0 && this.questionAnswer)) {
      this.detailsService.AddVote(this.addVoteObj).subscribe(
        result => {
          this.closePop();
        }, err => {
          console.error(err);
      });
    }
  }
  timesUp(event) {
    if (event.action === 'done') {
      this.closePop();
    }
  }

}
