import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewRef } from '@angular/core';
import { VotingSchemaType } from 'src/app/Models/meeting/VotingSchemaType';
import { AgendaItem } from 'src/app/Models/meeting/AgendaItem';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { Meeting } from 'src/app/Models/meeting/Meeting';
import { CountdownComponent } from 'ngx-countdown';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-meeting-vote',
  templateUrl: './meeting-vote.component.html',
  styles: []
})
export class MeetingVoteComponent implements OnInit {

  @Output() selectedTab: EventEmitter<any> = new EventEmitter();
  @Output() openTaskForm: EventEmitter<any> = new EventEmitter();

  allowVote = false;
  loading = false;
  allowEdit = false;
  showDiscards = false;
  disapledType = false;
  noSeleted = true;
  emtpyQuestion = false;
  noSelectedType = false;
  @Input() item: AgendaItem = new AgendaItem();
  @Input() meeting: Meeting;
  @Input() liveView: boolean;

  // QuestionType
  selectedQuestion: any;
  selectedQuestionType: number;
  VotingSchemaType = [
    { name: this.translate.instant('meetings.MultipleChoice'), value: VotingSchemaType.Choices, type: 0 },
    { name: this.translate.instant('meetings.SingleChoice'), value: VotingSchemaType.Text, type: 1 },
    { name: this.translate.instant('meetings.YesNo'), value: VotingSchemaType.YasOrNo, type: 2 }
  ];


  // Agenda
  validQuestionVoting = true;
  termsEditable = false;
  listItem: string;

  // voting
  noValidoption = false;
  optionEditable = true;
  optionItem = '';
  optionListing = [];
  optionListingClone: any[] = [];
  optionChecked = false;

  votingObj = {
    agendaItemEntityId: this.item.agendaItemEntityId,
    title: this.item.title,
    description: this.item.description,
    itemOrder: this.item.itemOrder,
    allowedVoting: false,
    allowedForComment: this.item.allowedForComment,
    votingModel: {
      title: '',
      votingSchemaType: null,
      votingAnswerTime: null,
      votingOptions: [],
      deletedAttachments: []
    },
  };

  // time
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  durationTime: number = null;
  noTimeInserted = false;
  startVotingTimer = false;
  changeVotingObj = {
    agendaItemEntityId: null,
    start: false,
    VotingTime: -1
  };

  stopedClicked = false;
  canEndVote = false;
  isShow = false;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private signalRService: SignalRServiceService,
    private detailsService: MeetingDetailsService) {
    this.signalRService.buildConnection();
  }

  ngOnInit() {
    this.signalRService.handleNewVotingEvent(this, this.updateVoting);
    // console.log(this.meeting);
    console.log('item', this.item);
    if (this.item.allowedVoting) {
      if (this.item.votingModel.votingAnswerTime > 0) {
        this.durationTime = this.item.votingModel.votingRemainingTime;
        this.startWithDuration();
      } else {
        this.startWithoutDuration();
      }
    }

    this.votingObj = {
      agendaItemEntityId: this.item.agendaItemEntityId,
      title: this.item.title,
      description: this.item.description,
      itemOrder: this.item.itemOrder,
      allowedVoting: false,
      allowedForComment: this.item.allowedForComment,
      votingModel: {
        title: '',
        votingSchemaType: null,
        votingAnswerTime: null,
        votingOptions: [],
        deletedAttachments: []
      },
    };
    this.changeVotingObj.agendaItemEntityId = this.item.agendaItemEntityId;

    if (this.item.votingModel) {
      this.optionListing = this.votingObj.votingModel.votingOptions = this.item.votingModel.votingOptions;
      this.optionListingClone = JSON.parse(JSON.stringify(this.optionListing));
      this.listItem = this.votingObj.votingModel.title = this.item.votingModel.title;
      this.selectedQuestion = this.VotingSchemaType.find(item => item.value === Number(this.item.votingModel.votingSchemaType));
      this.selectedQuestionType = this.votingObj.votingModel.votingSchemaType = Number(this.item.votingModel.votingSchemaType);
    }
    if (this.optionListing.length > 0) {
      this.termsEditable = false;
    }

    // startVoting
    this.detailsService.startVoting.subscribe(data => {
      if (data) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    });

  }
  updateVoting(context, agendaItemId, canEndVote) {
    if (context.item.agendaItemEntityId === agendaItemId) {
      if (canEndVote) {
        context.canEndVote = true;
        if (context.changeDetectorRef) {
          context.changeDetectorRef.detectChanges();
        }
        setTimeout(() => {
          if (context.changeDetectorRef && !(context.changeDetectorRef as ViewRef).destroyed) {
            context.changeDetectorRef.detectChanges();
          }
        });
      }
    }
  }

  setSelectedQuestionType(event) {
    this.selectedQuestion = event.value;
    this.selectedQuestionType = this.selectedQuestion.value;
    this.noSeleted = false;
    this.noSelectedType = false;

    if (this.selectedQuestionType === VotingSchemaType.Text) {
      this.optionListing = [];
    }
    if (this.selectedQuestionType === VotingSchemaType.Choices) {
      this.optionListing = this.optionListingClone;
    }

    if (this.selectedQuestionType === VotingSchemaType.YasOrNo) {
      this.optionListing = [this.translate.instant('swalMsg.yes'), this.translate.instant('swalMsg.no')];
    }
    // console.log('selectedQuestionType', this.selectedQuestionType);
    switch (this.selectedQuestionType) {
      case VotingSchemaType.Text:
        this.votingObj.votingModel.votingSchemaType = VotingSchemaType.Text;
        break;
      case VotingSchemaType.Choices:
        this.votingObj.votingModel.votingSchemaType = VotingSchemaType.Choices;
        break;
      case VotingSchemaType.YasOrNo:
        this.votingObj.votingModel.votingSchemaType = VotingSchemaType.YasOrNo;
        break;
    }


  }

  allowVoting() {
    this.allowVote = true;
    this.votingObj.allowedVoting = true;
    this.loading = false;
    this.listItem = '';
    this.optionItem = '';
    this.optionListing = [];
    this.selectedQuestionType = null;
    this.noSeleted = true;
  }

  setQuestion(event) {
    this.votingObj.votingModel.title = this.listItem;
  }

  addOptionItem(event) {
    if (this.optionItem) {
      this.optionListing.push(this.optionItem);
      if (this.optionListing.length >= 2) {
        this.noValidoption = false;
      }
    }
    setTimeout(() => {
      this.optionItem = undefined;
    });
  }

  removeOptionItem(index) {
    this.optionListing.splice(index, 1);
  }

  updateOptionItem(event, index) {
    // this.termsEditable = false;
    this.optionListing[index] = event.target.value;
  }

  discardTerms() {
    this.loading = false;
    this.termsEditable = false;
    this.showDiscards = false;
    this.listItem = this.votingObj.votingModel.title = this.item.votingModel.title;
    this.selectedQuestionType = Number(this.item.votingModel.votingSchemaType);
    this.selectedQuestion = this.VotingSchemaType.find(item => item.value === Number(this.item.votingModel.votingSchemaType));
    this.optionListing = JSON.parse(JSON.stringify(this.optionListingClone));
  }

  saveVoting() {
    if (this.selectedQuestionType == null) {
      this.noSelectedType = true;
      return;
    } else {
      this.noSelectedType = false;
    }

    if (this.listItem !== '') {
      this.emtpyQuestion = false;
      this.noSelectedType = false;

      if (this.selectedQuestionType === VotingSchemaType.Choices || this.selectedQuestionType === VotingSchemaType.YasOrNo) {
        this.votingObj.votingModel.votingOptions = this.optionListing;
        if (this.optionListing.length < 2) {
          this.noValidoption = true;
          return;
        }
      }

      if (this.selectedQuestionType === VotingSchemaType.Text) {
        this.optionListing = [];
        this.votingObj.votingModel.votingOptions = this.optionListing;
      }

      if (this.durationTime) {
        this.votingObj.votingModel.votingAnswerTime = this.durationTime;
      } else {
        this.votingObj.votingModel.votingAnswerTime = null;
      }

      this.loading = true;
      console.log('votingObj', this.votingObj);
      this.detailsService.saveMeetingAgendaItem(this.votingObj, []).subscribe(
        result => {
          this.loading = false;
          this.termsEditable = !this.termsEditable;
          this.showDiscards = false;
          this.detailsService.refreshAgendaItemSubject.next(true);
          this.selectedTab.emit();
        }, err => {
          console.error(err);
        });
    } else {
      this.noValidoption = false;
      this.emtpyQuestion = true;
    }
  }

  canEditQuestions() {
    this.allowEdit = true;
  }

  resetForm() {
    this.allowVote = false;
  }


  timesUp(event) {
    if (event.action === 'done') {
      this.detailsService.startVoting.next(false);
      this.stop();
    }
  }

  stop() {
    this.detailsService.startVoting.next(false);
    if (this.durationTime) {
      this.countdown.stop();
      this.changeVotingObj.VotingTime = this.durationTime;
    } else {
      this.changeVotingObj.VotingTime = -1;
    }
    this.changeVotingObj.VotingTime = -1;
    this.changeVotingObj.start = false;
    this.ChangeVote(this.changeVotingObj);
  }

  start() {
    this.detailsService.startVoting.next(true);
    if (this.durationTime) {
      this.startWithDuration();
    } else {
      Swal.fire({
        title: this.translate.instant('swalMsg.Startvotingwithouttime'),
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('swalMsg.yes'),
        cancelButtonText: this.translate.instant('swalMsg.no')
      }).then((result) => {
        if (result.value) {
          this.startWithoutDuration();
          this.ChangeVote(this.changeVotingObj);
        }
      });
    }
  }
  startWithDuration() {
    this.noTimeInserted = false;
    this.startVotingTimer = true;
    this.changeVotingObj.start = true;
    this.changeVotingObj.VotingTime = this.durationTime;
    this.ChangeVote(this.changeVotingObj);
  }
  startWithoutDuration() {
    this.noTimeInserted = true;
    this.startVotingTimer = true;
    this.changeVotingObj.start = true;
    this.changeVotingObj.VotingTime = -1;
  }
  // start() {
  //   if (this.durationTime) {
  //     this.changeVotingObj.start = true;
  //     this.changeVotingObj.VotingTime = this.durationTime;
  //     this.ChangeVote(this.changeVotingObj);
  //     this.noTimeInserted = false;
  //     this.startVotingTimer = true;
  //   } else {
  //     this.noTimeInserted = true;
  //     this.startVotingTimer = false;
  //   }
  // }

  ChangeVote(changeVotingObj) {
    this.detailsService.ChangeVote(changeVotingObj).subscribe(
      result => {
        if (!changeVotingObj.start) {
          this.detailsService.refreshAgendaItemSubject.next(true);
          this.selectedTab.emit();
        }
      }, err => {
        console.error(err);
      });
  }

  openCreateTask(event) {
    this.openTaskForm.emit(event);
  }

}
