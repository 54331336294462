export class Config {
  public static localhost = 'https://localhost:44339';
  public static connectapi = 'https://connectapi.expertapps.com.sa';
  public static connectSRapi = 'https://connectslr.expertapps.com.sa';

  // https://connectcore.expertapps.com.sa/
  public static coreUrl = 'https://connectcoreapi.expertapps.com.sa';
  public static coreSRUrl = 'https://connectcoreslr.expertapps.com.sa';

  // https://connectcoretest.expertapps.com.sa
  public static connectcoretestapi = 'https://connectcoretestapi.expertapps.com.sa';
  public static connectcoretestslr = 'https://connectcoretestslr.expertapps.com.sa';

  // https://connecttest.expertapps.com.sa
  public static connecttestapi = 'https://connecttestapi.expertapps.com.sa';
  public static connecttestslr = 'https://connecttestslr.expertapps.com.sa';

  // https://Connectreg.expertapps.com.sa
  public static connectregapi = 'https://Connectregapi.expertapps.com.sa';
  public static connectregslr = 'https://Connectregslr.expertapps.com.sa';

  ///////////////////////////////////////////////////////

  // https://connect.inndeve.com/
  public static publishUrl = 'https://connectapi.inndeve.com';
  public static publishSRUrl = 'https://connectapi.inndeve.com/slr';
  public static signalR = 'https://slr.inndeve.com';
  ///////////////////////////////////////////////////////

  // https://ConnectPFE.inndeve.com
  // public static connectPanelApi = 'https://ConnectPanelAPI.inndeve.com';
  // public static connectPanelSlr = 'https://ConnectNotificationAPI.inndeve.com';

  // https://concurelfe.concurel.com/
  public static concurelPanelApi = 'https://concurelpanelapi.concurel.com';
  public static concurelPanelSlr = 'https://concurelnotificationapi.concurel.com';

  // --------------------------------------------------------------------------------------------------//

  public static currentEnv = Config.concurelPanelApi;
  public static signalRUrl = Config.concurelPanelSlr + '/hubs';

  public static originUrl = location.origin;
  public static baseUrl = Config.currentEnv;

  public static loginUrl = Config.baseUrl + '/api/Authenticate/login';
  public static apiUrl = Config.baseUrl + '/api';

  public static googleSiteKey = '6Lcw-vkeAAAAAJ9a5aMnhFuaO6GqHGsgaJn18xWJ';
  public static googleApiKey = '6Lcw-vkeAAAAALakBWOyxWUSQFy6tXCqo0VN0OEa';

  public static spportEmail = 'info@inndeve.com';
  public static NoOfHoursToEndMeeting = 2;

  public static version = '0.0.0';
}
