import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';
import * as FileSaver from 'file-saver';

@Injectable({ providedIn: 'root' })
export class LookupsService {

  constructor(private http: HttpClient) {
  }

  getNationalities() {
    return this.http.get(`${Config.apiUrl}/Lookup/Nationalities`);
  }
  GetCountries() {
    return this.http.get(`${Config.apiUrl}/lookup/Countries`);
  }
  GetCities(countryId) {
    return this.http.get(`${Config.apiUrl}/lookup/Cities/${countryId}`);
  }
  getMenu() {
    return this.http.get(`${Config.apiUrl}/lookup/GetMenu`);
  }

  GetCurrentYear() {
    return this.http.get(`${Config.apiUrl}/lookup/GetCurrentYear`);
  }

  GetBillingType(): any {
    return this.http.get(`${Config.apiUrl}/Lookup/GetBillingType`);
  }


  downloadAttachment(file) {
    return this.http.get(
      `${Config.apiUrl}/Lookup/DownloadAttachment/${file.attachmentId}?Url=${file.path}`
    ).pipe().subscribe((response: any) => {
      const blob = this.dataURItoBlob(response.file); // base 64 to blob
      const blobFile: Blob = new Blob([blob], { type: response.contentType });
      FileSaver.saveAs(blobFile, response.name);
    });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
}
