import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './helpers/auth.guard';

// Layouts
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { EmptyLayoutComponent } from './layout/empty-layout/empty-layout.component';
import { SidebarLayoutComponent } from './layout/sidebar-layout/sidebar-layout.component';

import { ModalComponent } from './partial/modal/modal.component';
import { MyProfileComponent } from './partial/my-profile/my-profile.component';
import { CalenderViewHeaderComponent } from './partial/calender-view/calender-view-header/calender-view-header.component';

const routes: Routes = [
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
    ]
  },
  {
    path: 'admin',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Home' },
    children: [
      { path: '', component: MyProfileComponent, data: { breadcrumb: 'My Profile' } },
      { path: 'myprofile', component: MyProfileComponent, data: { breadcrumb: 'My Profile' } },
      { path: 'userdetails/:userId', component: MyProfileComponent, data: { breadcrumb: 'User details' } },
      { path: 'modal', component: ModalComponent, data: { breadcrumb: 'Modal' } },

      {
        path: 'dashboard', data: { breadcrumb: 'Dashboard' },
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'managegroups', data: { breadcrumb: 'Manage groups' },
        loadChildren: () => import('./modules/managegroups/managegroups.module').then(m => m.ManagegroupsModule)
      },
      {
        path: 'manageentity', data: { breadcrumb: 'Manage Entities' },
        loadChildren: () => import('./modules/manageentity/manageentity.module').then(m => m.ManageentityModule)
      },
      {
        path: 'manageusers', data: { breadcrumb: 'Manage Users' },
        loadChildren: () => import('./modules/manageusers/manageusers.module').then(m => m.ManageusersModule)
      },
      {
        path: 'tasks', data: { breadcrumb: 'Tasks' },
        loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule)
      },
      {
        path: 'meetings', data: { breadcrumb: 'Meetings' },
        loadChildren: () => import('./modules/meetings/meetings.module').then(m => m.MeetingsModule)
      },
      {
        path: 'packages', data: { breadcrumb: 'Packages' },
        loadChildren: () => import('./modules/packages/packages.module').then(m => m.PackagesModule) },
      {
        path: 'subscribers', data: { breadcrumb: 'Subscribers' }
        , loadChildren: () => import('./modules/subscribers/subscribers.module').then(m => m.SubscribersModule) },
      { path: 'calenderview', component: CalenderViewHeaderComponent, data: { breadcrumb: '' } },
      {
        path: 'payment', data: { breadcrumb: 'Payment Method' },
        loadChildren: () => import('./modules/payment-method/payment-method.module').then(m => m.PaymentMethodModule)
      },
      {
        path: 'voting', data: { breadcrumb: 'Voting' },
        loadChildren: () => import('./modules/voting/voting.module').then(m => m.VotingModule)
      },

      // { path: 'votingprint', loadChildren: () => import('./modules/votingprint/votingprint.module').then(m => m.VotingprintModule) },
    ]
  },
  {
    path: 'admin',
    component: SidebarLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: 'messages', component: MessagesComponent, data: { breadcrumb: 'Messages' } }
      {
        path: 'messages', data: { breadcrumb: 'Messages' },
        loadChildren: () => import('./modules/messages/messages.module').then(m => m.MessagesModule)
      },
    ]
  },
  {
    path: 'admin',
    component: EmptyLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: 'livemeeting/:meetingId', component: LiveMeetingComponent, data: { breadcrumb: 'Live Meeting' } },
      {
        path: 'livemeeting', data: { breadcrumb: 'Live Meeting' },
        loadChildren: () => import('./modules/live-meeting/live-meeting.module').then(m => m.LiveMeetingModule)
      },
      {
        path: 'multilivemeeting',
        loadChildren: () => import('./modules/multi-live-meeting/multi-live-meeting.module').then(m => m.MultiLiveMeetingModule)
      },

      // {
      //   path: 'multijistiusers',
      //   loadChildren: () => import('./multi-jisti-users/multi-jisti-users.module').then(m => m.MultiJistiUsersModule)
      // },
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
