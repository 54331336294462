import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AgendaItem } from 'src/app/Models/meeting/AgendaItem';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';

@Component({
  selector: 'app-agenda-form',
  templateUrl: './agenda-form.component.html',
  styles: []
})
export class AgendaFormComponent implements OnInit {

  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Output() closePopAndSave: EventEmitter<any> = new EventEmitter();
  @Input() item: AgendaItem;
  @Input() meetingId;
  @Input() itemsCount: number;
  loading = false;
  editFormGroup: FormGroup;
  files = [];
  deletedFiles = [];

  constructor(
    private detailsService: MeetingDetailsService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.editFormGroup = this.formBuilder.group({
      title: ['', [Validators.required]],
      itemOrder: ['']
    });
    if (this.item) {
      this.editFormGroup.controls.title.setValue(this.item.title);
      this.editFormGroup.controls.itemOrder.setValue(this.item.itemOrder);
      this.files = JSON.parse(JSON.stringify(this.item.attachments));
      this.files = this.files.filter(item => !item.isDeleted);
      console.log(this.files);
    }
  }

  saveItem() {
    let agendaItem;
    agendaItem = {
      title: this.editFormGroup.controls.title.value,
      itemOrder: this.editFormGroup.controls.itemOrder.value
    };
    if (this.item) {
      agendaItem.agendaItemEntityId = this.item.agendaItemEntityId;
      agendaItem.deletedAttachments = this.deletedFiles;
    } else {
      agendaItem.meetingEntityId = this.meetingId;
      agendaItem.itemOrder = this.itemsCount + 1;
    }
    if (this.editFormGroup.valid) {
      this.loading = true;
      this.detailsService.saveMeetingAgendaItem(agendaItem, this.files).subscribe(
        data => {
          this.loading = false;
          this.closePopAndSave.emit();
        }, err => {
          console.error(err);
        });
    }
  }
  closePop() {
    this.cancelPop.emit();
  }
  counter(i: number) {
    return new Array(i);
  }
}
