import { User } from './user';
export class Message {
    messageId: number;
    messageTitle: string;
    messageContent: string;
    created: string;
    upVotes: number;
    downVotes: number;
    isPinned: boolean;
    isNotified: boolean;
    isDraft: boolean;
    canVote: boolean;
    canReply: boolean;
    canPin: boolean;
    isCreator: boolean;
    commentsNumber: number;
    collectionGroupingMessageIds: [];
    allCollectionsInGroup: [];
    collectionGroupingSender: number;
    messageCreatorData: {
        messageCreatorId: '',
        messageCreatorName: '',
        userName: '',
        userProfilePicture: ''
    };
    messageCollectionData: {
        abbreviation: '',
        collectionArabicName: '',
        collectionId: 0,
        collectionName: ''
    };
    messageReceivers: [];
    messageAttachments: [];
    messageGroupData: {
        abbreviation: '',
        groupName: '',
        groupArabicName: '',
        groupId: 0
    };
    externalUsers: User[];
    delegated: number;
    voteType?: any;
    canDelegate?: boolean;
    CanShareMessage?: boolean;
    canShowStatus?: boolean;
    messageUserData?: any;
    isSeen?: boolean;
    messageCreated?: string;
}
