import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CommentsModel } from 'src/app/Models/messages/CommentsModel';
import { CommentsFilterModel } from 'src/app/models/messages/CommentsFilterModel';
import { MessagesService } from 'src/app/services/messages.service';
import { Message } from 'src/app/Models/messages/Message';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user/user';
import { Collection } from 'src/app/models/messages/Collection';
// import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { CommentAsModal } from 'src/app/models/messages/CommentAsModal';
import { log } from 'util';


@Component({
  selector: 'app-message-comments',
  templateUrl: './message-comments.component.html'
})
export class MessageCommentsComponent implements OnInit {
  language = localStorage.getItem('language') || 'en';
  @Output() updateList = new EventEmitter<boolean>();
  dataLoading = false;
  loading = false;
  replyLoading = false;
  // repliesListLoading: boolean[] = [];
  myComment = '';
  editabelComment = '';
  showMyCommentBtn = false;
  @Input() messageId: number;
  @Input() TaskComment = false;
  messageGroupData: {};
  // toggleAddReplay: boolean[] = [];
  // toggleEditReplay: boolean[] = [];
  commentsObject: CommentsModel = new CommentsModel();
  commentspageData: [];

  messageDetails: Message = new Message();
  addComentobj = {
    GroupId: 0,
    CommentContent: '',
    CurrentCollectionGroupingId: null,
    EntityId: 0,
    ObjectType: '',
    CreatedAsId: null,
    CreatedAsCollectionId: null
  };
  GetReplyObj = {
    CommentId: 0,
    PageSize: 5,
    ParentId: 0
  };

  commentsFilterModel: CommentsFilterModel = new CommentsFilterModel();

  currentUser: User = {
    name: '',
  } as User;

  myCollections: Collection[] = [];
  @Input() groupId: number;
  @Input() ObjectType: string;
  commentReply = '';
  repliesList: { parentId: number, commentId: number, replies: any }[] = [];
  toggleAddReplay: string;
  toggleEditReplay: string;
  repliesListLoading: string;

  @ViewChild('comment', {static: false}) commentElement: ElementRef;

  // private commentEditor = InlineEditor;
  // EditorConfig = {
  //   language: (document.documentElement.lang === 'ar') ? 'ar' : 'en'
  // };

  commentAs: CommentAsModal = new CommentAsModal();
  commentAsList: CommentAsModal[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private messagesService: MessagesService,
    private authenticationService: AuthenticationService,
    private signalRService: SignalRServiceService,
    private router: Router) {
      this.signalRService.buildConnection();
    }

  ngOnInit() {
    this.getMessageComments();
    this.signalRService.handleLoadMessageCommentsEvent(this, this.loadMessageComments);
    this.signalRService.handleLoadCommentReplaiesEvent(this, this.updateReplies);
  }

  loadCommentAsList() {
    if (this.messageId) {
      this.messagesService.CommentOnMessageAsWho(this.messageId).subscribe(
        result => {
          this.commentAsList = result as CommentAsModal[];
          this.commentAs = this.commentAsList.find(user => user.currentUser === true);
        }, err => {
          console.error(err);
        });
    }
  }

  loadMessageComments(context, messageId) {
    if (messageId === context.messageId) {
      context.getMessageComments();
      context.changeDetectorRef.detectChanges();
    }
  }
  updateReplies(context, commentId, parentId) {
    // context.getMessageComments();
    context.getReplies(parentId, commentId, true);
    context.changeDetectorRef.detectChanges();
  }

  resetChild() {
    this.myComment = '';
    this.commentReply = '';
    this.toggleAddReplay = '';
    this.toggleEditReplay = '';
    this.repliesListLoading = '';
    this.repliesList = [];
    setTimeout(() => {
      if (this.commentElement) {
        this.textAreaAdjust(this.commentElement.nativeElement);
      }
    }, 0);
  }
  getMessageComments() {
    if (this.messageId) {
      this.dataLoading = true;
      this.currentUser = this.authenticationService.currentUserValue;
      this.commentsFilterModel.messageId = this.messageId;
      this.commentsFilterModel.ObjectType = this.ObjectType;
      this.messagesService.GetComments(this.commentsFilterModel).subscribe(
        result => {
          this.dataLoading = false;
          this.commentsObject = result as CommentsModel;
          this.commentspageData = this.commentsObject.pageData;
          this.loadCommentAsList();
        }, err => {
          this.dataLoading = false;
          console.error(err);
        });

      // get my collection
      this.getGroupCollections(this.groupId);
    }
  }

  onEditorReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }
  onEditorChange( event ) {
  }
  // GetCollections() {
  //   this.messagesService.GetCollections(this.messageId).subscribe(
  //     result => {
  //       console.log('GetCollections', result);
  //       // this.addComentobj.CurrentCollectionGroupingId = result as [];
  //     }, err => {
  //       console.error(err);
  //     });
  // }

  getGroupCollections(groupId) {
    // my entities inside group
    this.messagesService.GetGroupCollections(groupId, false)
      .subscribe(data => {
        this.myCollections = data as Collection[];
      },
      error => {
        console.log(error);
      });
  }

  addMyComment(e, index = null) {
    e.preventDefault();
    this.loading = true;
    if (this.myComment) {
      this.closeAllCommentPanels();
      this.addComentobj.CommentContent = this.myComment;
      this.addComentobj.GroupId = this.groupId;
      this.addComentobj.EntityId = this.messageId;
      this.addComentobj.ObjectType = this.ObjectType;
      this.addComentobj.CurrentCollectionGroupingId = (this.myCollections.length) ? this.myCollections[0].collectionGroupingId : null;

      if (this.commentAs && !this.commentAs.currentUser) {
        this.addComentobj.CreatedAsId = this.commentAs.id;
        this.addComentobj.CreatedAsCollectionId = this.commentAs.collectionEntityId;
      } else {
        this.addComentobj.CreatedAsId = null;
        this.addComentobj.CreatedAsCollectionId = null;
      }

      this.messagesService.AddComment(this.addComentobj).subscribe(
        result => {
          this.loading = false;
          this.myComment = '';
          this.getMessageComments();
          this.updateList.emit(true);
          setTimeout(() => { this.textAreaAdjust(this.commentElement.nativeElement); }, 0);
        }, err => {
          this.loading = false;
          console.error(err);
        });
    }
  }

  editComment(comment) {
    this.replyLoading = true;
    let editObj;
    editObj = {
      Id: comment.commentId,
      Content: comment.content,
      ObjectType: this.ObjectType
    };

    if (this.commentAs && !this.commentAs.currentUser) {
      editObj.CreatedAsId = this.commentAs.id;
      editObj.CreatedAsCollectionId = this.commentAs.collectionEntityId;
    } else {
      editObj.CreatedAsId = null;
      editObj.CreatedAsCollectionId = null;
    }
    this.messagesService.EditComment(editObj).subscribe(
      result => {
        this.replyLoading = false;
        this.closeAllCommentPanels();
        this.getMessageComments();
      }, err => {
        console.error(err);
      });
  }

  addReply(parentId, commentId) {
    this.replyLoading = true;
    let replyObj;
    replyObj = {
      GroupId: this.groupId,
      ReplyContent: this.commentReply,
      CurrentCollectionGroupingId: (this.myCollections.length) ? this.myCollections[0].collectionGroupingId : null,
      CommentId: commentId,
      ParentId: parentId
      // rsolved bug 22890
      // ParentId: (parentId === commentId) ? null : parentId
    };

    if (this.commentAs && !this.commentAs.currentUser) {
      replyObj.CreatedAsId = this.commentAs.id;
      replyObj.CreatedAsCollectionId = this.commentAs.collectionEntityId;
    } else {
      replyObj.CreatedAsId = null;
      replyObj.CreatedAsCollectionId = null;
    }
    this.messagesService.AddReply(replyObj).subscribe(
      result => {
        this.replyLoading = false;
        this.commentReply = '';
        this.getMessageComments();
        this.closeAllCommentPanels();

        const index = this.repliesList.findIndex(value => value.commentId === commentId && value.parentId === parentId);
        this.repliesList.splice(index, 1);

        this.getReplies(parentId, commentId, true);
        // this.updateList.emit(true);
      }, err => {
        console.error(err);
      });
  }
  editReply(reply, commentId, parentId) {
    this.replyLoading = true;
    let replyObj;
    replyObj = {
      Id: reply.replyId,
      Content: reply.content
    };

    if (this.commentAs && !this.commentAs.currentUser) {
      replyObj.CreatedAsId = this.commentAs.id;
      replyObj.CreatedAsCollectionId = this.commentAs.collectionEntityId;
    } else {
      replyObj.CreatedAsId = null;
      replyObj.CreatedAsCollectionId = null;
    }
    this.messagesService.EditReply(replyObj).subscribe(
      result => {
        this.replyLoading = false;
        this.closeAllCommentPanels();
        this.getReplies(parentId, commentId, true);
      }, err => {
        console.error(err);
      });
  }

  getReplies(parentId, commentId, update) {
    if (this.objectInRepliesList(parentId, commentId) && !update) {
      const index = this.repliesList.findIndex(value => value.commentId === commentId && value.parentId === parentId);
      this.repliesList.splice(index, 1);
    } else {
      this.GetReplyObj.CommentId = commentId;
      this.GetReplyObj.ParentId = parentId;
      this.repliesListLoading = 'loading-' + commentId + parentId;

      this.messagesService.GetReplies(this.GetReplyObj).subscribe(
        result => {
          this.repliesListLoading = '';
          if (update) { // remove replies to add new update
            const oldIndx = this.repliesList.findIndex(value => value.commentId === commentId && value.parentId === parentId);
            this.repliesList.splice(oldIndx, 1);
            this.repliesList.push({parentId, commentId, replies: result['pageData']});
          }
          if (!this.objectInRepliesList(parentId, commentId)) {
            this.repliesList.push({parentId, commentId, replies: result['pageData']});
          }

          console.log(this.repliesList);
        }, err => {
          console.error(err);
        });
    }
  }

  returnRepliesList(parentId, commentId) {
    const obj = this.repliesList.find((item) => item.parentId === parentId && item.commentId === commentId);
    if (obj && obj !== undefined) {
      return obj.replies;
    }
    // return false;
  }
  objectInRepliesList(parentId, commentId) {
    if (!this.repliesList.some((item) => item.parentId === parentId && item.commentId === commentId)) {
      return false;
    }
    return true;
  }

  closeAllCommentPanels() {
    this.commentReply = '';
    this.toggleAddReplay = '';
    this.toggleEditReplay = '';
  }
  canReply(value) {
    if (this.toggleAddReplay === value) {
      this.closeAllCommentPanels();
    } else {
      this.closeAllCommentPanels();
      this.toggleAddReplay = value;
    }
  }
  canComment(value) {
    if (this.toggleEditReplay === value) {
      this.closeAllCommentPanels();
    } else {
      this.closeAllCommentPanels();
      this.toggleEditReplay = value;
    }
  }

  textAreaAdjust(e) {
    let target = e.target;
    if (target === undefined) { target = e; }
    target.style.height = '26px';
    target.style.height = target.scrollHeight + 'px';
  }
  trim(value) { return value.trim(); }
  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }
  setSelectedSortItems(event) {
    this.commentsFilterModel.SortItem = Number(event.value);
    this.commentsFilterModel.pageNumber = 0;
    this.getMessageComments();
  }
  onKeydown(event, index) {
    if (event.key === "Enter") {
      this.canComment(index);
      // this.getMessageComments();
    }
  }

}
