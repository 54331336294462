
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlModule } from 'ngx-owl-carousel';

import { DemoMaterialModule } from 'src/app/helpers/material-modules';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateModule } from '@ngx-translate/core';

import { ShortNamePipe } from '../helpers/shortName.pipe';
import { DndDirective } from 'src/app/helpers/dnd.directive';
import { SanitizeHtmlPipePipe } from 'src/app/helpers/sanitize-html-pipe.pipe';
import { OnlynumberDirective } from 'src/app/helpers/onlynumber.directive';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { FilesViewerComponent } from './files-viewer/files-viewer.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MustMatchDirective } from 'src/app/helpers/must-match.directive';
import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { ShowAuthedDirectiveDirective } from 'src/app/helpers/show-authed-directive.directive';
import { TimeAgoPipe } from 'time-ago-pipe';
import { TimeDiffPipe } from 'src/app/helpers/time-left.pipe';
import { MatNativeDateModule } from '@angular/material';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ExportAsModule } from 'ngx-export-as';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { CalendarModule } from 'angular-calendar';
import { CreateEditTaskComponent } from './create-edit-task/create-edit-task.component';
import { MessageCommentsComponent } from './message-comments/message-comments.component';
import { CalenderViewComponent } from './calender-view/calender-view.component';
import { CalenderViewHeaderComponent } from './calender-view/calender-view-header/calender-view-header.component';
import { AgendaListComponent } from './agenda-list/agenda-list.component';
import { AgendaItemComponent } from './agenda-list/agenda-item/agenda-item.component';
import { AgendaFormComponent } from './agenda-list/agenda-item/agenda-form/agenda-form.component';
import { MeetingVoteComponent } from './agenda-list/meeting-vote/meeting-vote.component';
import { VotingTabsComponent } from './agenda-list/meeting-vote/voting-tabs/voting-tabs.component';
import { HeaderComponent } from './../layout/shared/header/header.component';
import { SideBarComponent } from '../layout/shared/sidebar/sidebar.component';
import { SidebarLayoutComponent } from '../layout/sidebar-layout/sidebar-layout.component';
import { RouterModule } from '@angular/router';
import { ChatComponent } from '../layout/chat/chat.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { AgoTimePipe } from '../helpers/ago-time.pipe';
import { JitsiMeetingComponent } from './jitsi-meeting/jitsi-meeting.component';
import { MultiUserJitsiComponent } from './multi-user-jitsi/multi-user-jitsi.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DownloadDirective } from '../helpers/download.directive';
import { Config } from '../models/config';

export function getDutchPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  const lang = localStorage.getItem('language') || 'en';
  paginatorIntl.itemsPerPageLabel = (lang === 'en') ? 'Entries per page' : 'إدخالات لكل صفحة';
  paginatorIntl.nextPageLabel = (lang === 'en') ? 'Next page' : 'الصفحة التالية';
  paginatorIntl.previousPageLabel = (lang === 'en') ? 'Previous page' : 'الصفحة السابقة';
  paginatorIntl.firstPageLabel = (lang === 'en') ? 'First page' : 'الصفحة الاولي';
  paginatorIntl.lastPageLabel = (lang === 'en') ? 'Last page' : 'الصفحة الاخيرة';
  return paginatorIntl;
}

// function countdownConfigFactory(): CountdownGlobalConfig {
//   return;
// }
@NgModule({
  declarations: [
    DndDirective,
    OnlynumberDirective,
    ShortNamePipe,
    SanitizeHtmlPipePipe,
    FileManagerComponent,
    FilesViewerComponent,
    MustMatchDirective,
    DownloadDirective,
    AlertComponent,
    ModalComponent,
    ShowAuthedDirectiveDirective,
    TimeAgoPipe,
    AgoTimePipe,
    TimeDiffPipe,
    CreateEditTaskComponent,
    MessageCommentsComponent,
    CalenderViewComponent,
    CalenderViewHeaderComponent,
    AgendaListComponent,
    AgendaItemComponent,
    AgendaFormComponent,
    MeetingVoteComponent,
    VotingTabsComponent,
    HeaderComponent,
    SideBarComponent,
    SidebarLayoutComponent,
    ChatComponent,
    MyProfileComponent,
    JitsiMeetingComponent,
    MultiUserJitsiComponent,
  ],
  imports: [
    RecaptchaModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DemoMaterialModule,
    InfiniteScrollModule,
    TranslateModule.forChild({}),
    RouterModule,
    DemoMaterialModule,
    MatNativeDateModule,
    CKEditorModule,
    InfiniteScrollModule,
    OwlModule,
    ExportAsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    // NgxMaterialTimepickerModule.setLocale(localStorage.getItem('language') == 'en' ? 'en-US':'ar-AE'),
    NgxMaterialTimepickerModule,
    CountdownModule,
    CalendarModule,
  ],
  exports: [
    RecaptchaModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MessageCommentsComponent,
    OwlModule,
    DemoMaterialModule,

    InfiniteScrollModule,
    TranslateModule,
    DndDirective,
    OnlynumberDirective,
    ShortNamePipe,
    SanitizeHtmlPipePipe,
    FileManagerComponent,
    FilesViewerComponent,
    MustMatchDirective,
    DownloadDirective,
    AlertComponent,
    ModalComponent,
    ShowAuthedDirectiveDirective,
    TimeAgoPipe,
    TimeDiffPipe,
    AgoTimePipe,
    DemoMaterialModule,
    MatNativeDateModule,
    CKEditorModule,
    InfiniteScrollModule,
    OwlModule,
    ExportAsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMaterialTimepickerModule,
    CountdownModule,
    CalendarModule,
    CreateEditTaskComponent,
    CalenderViewComponent,
    CalenderViewHeaderComponent,
    AgendaListComponent,
    AgendaItemComponent,
    AgendaFormComponent,
    MeetingVoteComponent,
    VotingTabsComponent,
    HeaderComponent,
    SideBarComponent,
    SidebarLayoutComponent,
    ChatComponent,
    MyProfileComponent,
    JitsiMeetingComponent,
    MultiUserJitsiComponent
  ],
  providers: [
    DatePipe,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: Config.googleSiteKey } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (locale: string) => locale,
      deps: [LOCALE_ID],
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: true } },
    { provide: CountdownGlobalConfig }, // , useFactory: countdownConfigFactory
    { provide: LOCALE_ID, useValue: localStorage.getItem('language') || 'en' },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }

  ]
})

export class SharedModule { }
