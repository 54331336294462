import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/models/config';
import { InviteeTabsData } from 'src/app/models/meeting/InviteeTabsData';
import { InviteeTabsFilter } from 'src/app/models/meeting/InviteeTabsFilter';
import { BehaviorSubject } from 'rxjs';
import * as FileSaver from 'file-saver';
import { Meeting } from '../Models/meeting/Meeting';

@Injectable({
  providedIn: 'root'
})
export class MeetingDetailsService {
  originUrl = Config.originUrl;
  refreshDetails = new BehaviorSubject<boolean>(false);
  refreshMeetingDetails = new BehaviorSubject<any>(null);
  refreshAgendaItemSubject = new BehaviorSubject<boolean>(false);
  showBtn = new BehaviorSubject<boolean>(false);
  startVoting = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  GetMeetingDetails(id: any) {
    return this.http.get(`${Config.apiUrl}/meetings/GetMeetingDetails/` + id);
  }
  EditMeeting(meetingInfo) {
    return this.http.post(`${Config.apiUrl}/meetings/EditMeeting/`, meetingInfo);
  }
  GoLive(id) {
    return this.http.get(`${Config.apiUrl}/meetings/GoLive/` + id);
  }
  EndMeeting(id) {
    return this.http.get(`${Config.apiUrl}/meetings/EndMeeting/` + id);
  }
  DeleteMeeting(id) {
    return this.http.get(`${Config.apiUrl}/meetings/DeleteMeeting/` + id);
  }
  CancelMeeting(id) {
    return this.http.get(`${Config.apiUrl}/meetings/CancelMeeting/` + id);
  }
  GetGroupCollectionAndUsers(groupId, meetingId) {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${Config.apiUrl}/meetings/GetGroupCollectionUsersForMeetingInvitation?groupId=` + groupId + '&meetingId=' + meetingId);
  }
  GetExternalUsers(meetingId) {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${Config.apiUrl}/meetings/GetGroupCollectionUsersForMeetingInvitation?groupId=0&meetingId=` + meetingId);
  }
  GetCollectionsAndUsersToInvite(groupId, meetingId, searchText = '') {
    let endPoint = 'GetGroupCollectionAndUsers';
    let params;
    if (meetingId) {
      endPoint = 'GetGroupCollectionUsersWithoutinvitee';
    }
    params = {
      meetingId: meetingId ? meetingId : null,
      searchText: searchText ? searchText : ''
    };
    return this.http.get(`${Config.apiUrl}/meetings/${endPoint}/${groupId}`, { params });
  }
  CreateMOM(momObj) {
    return this.http.post(`${Config.apiUrl}/meetings/CreateMOM/`, momObj);
  }

  GetMOM(meetId) {
    return this.http.get(`${Config.apiUrl}/meetings/GetMOM/` + meetId);
  }

  GenerateMOM(meetingId): any{
    return this.http.get(`${Config.apiUrl}/meetings/GenerateMOM/${meetingId}`, { responseType: 'blob' });
  }

  public saveAsPDFFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/pdf'
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + '.pdf');
  }

  RequestOneTimePassword(meetingId){
    return this.http.get(`${Config.apiUrl}/meetings/RequestFirstOneTimePassword/?meetingId=` + meetingId);
  }
  SubmitMySignature(obj): any{
    return this.http.post(`${Config.apiUrl}/meetings/SubmitMySignature`, obj);
  }

  /////////////////////////////////////////////////////////////

  ListAgendItems(obj) {
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/ListAgendItems/`, obj);
  }
  GetAgendItemDetails(id) {
    const obj = {
      id,
      PageNumber: 0,
      PageSize: 100
    };
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/GetAgendItemDetails/`, obj);
  }
  saveMeetingAgendaItem(agendaInfo, files) {
    let url = '';
    if (agendaInfo.agendaItemEntityId) {
      url = 'EditMeetingAgendaItem';
    } else {
      url = 'AddMeetingAgendaItem';
    }
    const formData = new FormData();
    files.forEach(file => {
      if (file.attachmentId === undefined) { // send new files
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(agendaInfo));
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/` + url, formData);
  }
  DeleteMeetingAgendaItem(id) {
    return this.http.get(`${Config.apiUrl}/meetingAgendaItems/DeleteMeetingAgendaItem/` + id);
  }
  AddRecommendation(obj) {
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/AddRecommendation/`, obj);
  }
  DeleteRecommendation(id) {
    return this.http.get(`${Config.apiUrl}/meetingAgendaItems/DeleteRecommendation/` + id);
  }
  ListAgendItemComments(id) {
    const obj = {
      id,
      PageNumber: 0,
      PageSize: 100
    };
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/ListAgendItemComments/`, obj);
  }
  ListAgendItemRecommendations(id) {
    const obj = {
      id,
      PageNumber: 0,
      PageSize: 100
    };
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/ListAgendItemRecommendations/`, obj);
  }
  ListAgendItemVoting(id) {
    const obj = {
      id,
      PageNumber: 0,
      PageSize: 100
    };
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/ListAgendItemVoting/`, obj);
  }

  /////////////////////////////////////////////////////////////

  ListInvitees(obj: InviteeTabsFilter) {
    return this.http.post<InviteeTabsData>(`${Config.apiUrl}/meetingInvitees/ListInvitees/`, obj);
  }
  SendReminder(meetingId, id) {
    return this.http.get(`${Config.apiUrl}/meetingInvitees/SendReminder?meetingId=` + meetingId + '&meetingInviteeId=' + id);
  }
  AddMeetingInvitees(obj) {
    return this.http.post(`${Config.apiUrl}/meetingInvitees/AddMeetingInvitees/`, obj);
  }
  SubmitMeetingInvitaion(obj) {
    return this.http.post(`${Config.apiUrl}/meetingInvitees/SubmitMeetingInvitaion/`, obj);
  }
  RemoveMeetingInvitee(id) {
    return this.http.get(`${Config.apiUrl}/meetingInvitees/RemoveMeetingInvitee/` + id);
  }
  commentAsWho(id) {
    return this.http.get(`${Config.apiUrl}/meetingInvitees/CommentAsWho/` + id);
  }

  /////////////////////////////////////////////////////////////

  GetMeetingActivities(meetingId) {
    return this.http.get(`${Config.apiUrl}/audits/GetMeetingActivities/` + meetingId);
  }

  ChangeVote(votingObj) {
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/ChangeVote/`, votingObj);
  }
  AddVote(votingObj) {
    return this.http.post(`${Config.apiUrl}/meetingAgendaItems/AddVote/`, votingObj);
  }


  AttendMeeting(id): any {
    return this.http.get(`${Config.apiUrl}/meetingInvitees/AttendMeeting/` + id);
  }
}
