import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  hasUpdate = false;

  constructor(
    public translate: TranslateService,
    private swUpdate: SwUpdate
  ) {
    // localStorage.setItem('language', 'en');
    const lang = localStorage.getItem('language') || 'en';
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    translate.use(lang);
  }

  ngOnInit(): void {
    // check for platform update
    if (this.swUpdate.isEnabled) {
      interval(1000).subscribe(() => this.swUpdate.checkForUpdate().then(() => {
        // checking for updates
        console.log('checking for updates');
      }));
    }
    this.swUpdate.available.subscribe(() => {
      this.hasUpdate = true;
    });
  }

  reloadSite(): void {
    location.reload();
  }


}
