import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyprofileService {
  refreshDetails = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }
  GetUser(id): any {
    return this.http.get(`${Config.apiUrl}/accounts/GetUser/` + id);
  }
  GoOffline(status): any {
    return this.http.get(`${Config.apiUrl}/accounts/GoOffline?status=` + status);
  }
  getEntities(entitiesObj): any {
    return this.http.post(`${Config.apiUrl}/CollectionEntity/GetCollectionsForUserProfile`, entitiesObj);
  }
  GetUserGroups(UserGroupsObj): any {
    return this.http.post(`${Config.apiUrl}/groups/GetGroupsForUserProfile`, UserGroupsObj);
  }
}
