import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/models/config';
import { TaskForm } from 'src/app/Models/tasks/TaskForm';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  // toggleSubTaskDetails = new BehaviorSubject(false);
  // currentCaseforSubDetails = this.toggleSubTaskDetails.asObservable();
  refreshBoardSubject = new BehaviorSubject<boolean>(false);

  refreshDetails = new BehaviorSubject<boolean>(false);
  requestDateDone = new BehaviorSubject<boolean>(false);
  submitType = new BehaviorSubject<any>(null);

  markDoneSucss = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  GetTaskPriorities() {
    return this.http.get(`${Config.apiUrl}/tasks/GetTaskPriorities/`);
  }
  GetCollectionsByGroupId(groupId) {
    return this.http.get(`${Config.apiUrl}/CollectionEntity/GetCollectionsByGroupId/` + groupId);
  }
  GetTask(taskId, taskType) {
    return this.http.get(`${Config.apiUrl}/tasks/GetTask?` + 'taskId=' + taskId + '&taskType=' + taskType);
  }
  saveTask(task: TaskForm, files) {
    let url = '';
    if (task.Id) {
      url = 'EditTask';
    } else {
      url = 'CreateTask';
    }
    const formData = new FormData();
    files.forEach(file => {
      if (file.attachmentId === undefined) { // send new files
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(task));
    return this.http.post(`${Config.apiUrl}/tasks/` + url, formData);
  }
  FollowTask(TaskId, TaskType) {
    return this.http.post(`${Config.apiUrl}/tasks/FollowTask`, { TaskId, TaskType });
  }
  ListingAttachments(AttachmentsObject) {
    return this.http.post(`${Config.apiUrl}/tasks/ListingAttachments`, AttachmentsObject);
  }
  UpdateTaskOutputs(data, files) {
    const formData = new FormData();
    files.forEach(file => {
      if (file.attachmentId === undefined) { // send new files
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(data));
    return this.http.post(`${Config.apiUrl}/tasks/UpdateTaskOutputs/`, formData);
  }
  ListingTaskActivities(ActivitiesObject) {
    return this.http.post(`${Config.apiUrl}/tasks/ListingTaskActivities`, ActivitiesObject);
  }
  ListingFollowers(FollowersObject) {
    return this.http.post(`${Config.apiUrl}/tasks/ListingFollowers`, FollowersObject);
  }
  GetTasksByGroup(TasksFilterModel) {
    return this.http.post(`${Config.apiUrl}/tasks/GetTasksByGroup`, TasksFilterModel);
  }
  GetGroupCollections(groupId) {
    return this.http.get(`${Config.apiUrl}/CollectionEntity/GetCollectionsByGroupId/` + groupId);
  }
  GetAssigneesByTaskId(taskId, subTaskId) {
    return this.http.get(`${Config.apiUrl}/tasks/GetAssigneesByTaskId/` + taskId + '?SubTaskId=' + subTaskId);
  }
  ForwardSubTask(forwardId, subTaskId) {
    return this.http.get(`${Config.apiUrl}/tasks/ForwardSubTask?forwardId=` + forwardId + '&subTaskId=' + subTaskId);
  }
  CreateSubTask(subTaskObject) {
    return this.http.post(`${Config.apiUrl}/tasks/CreateSubTask`, subTaskObject);
  }
  MarkTaskAsDone(data, files) {
    const formData = new FormData();
    files.forEach(file => {
      if (file.attachmentId === undefined) { // send new files
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(data));
    return this.http.post(`${Config.apiUrl}/tasks/MarkTaskAsDone`, formData);
  }
  UpdateTaskStatus(data) {
    return this.http.post(`${Config.apiUrl}/tasks/UpdateTaskStatus`, data);
  }
  RespondMarkAsDone(data) {
    return this.http.post(`${Config.apiUrl}/tasks/RespondMarkAsDone`, data);
  }
  RequestNewDueDate(data) {
    return this.http.post(`${Config.apiUrl}/tasks/RequestNewDueDate`, data);
  }
  RespondDueDateRequest(data) {
    return this.http.post(`${Config.apiUrl}/tasks/RespondDueDateRequest`, data);
  }
  DeleteTask(taskId, taskType) {
    return this.http.get(`${Config.apiUrl}/tasks/DeleteTask?` + 'taskId=' + taskId + '&taskType=' + taskType);
  }

  GetMyTasks(MyTaskFilterObj) {
    return this.http.post(`${Config.apiUrl}/tasks/GetMyTasks`, MyTaskFilterObj);
  }
  GetUserCollections() {
    return this.http.get(`${Config.apiUrl}/CollectionEntity/GetUserCollections/`);
  }
  GetTasksByEntity(TasksFilterModel) {
    return this.http.post(`${Config.apiUrl}/tasks/GetTasksByEntity`, TasksFilterModel);
  }
  GetEntityMembers(collectionEntityId) {
    return this.http.get(`${Config.apiUrl}/CollectionEntity/GetEntityMembers/` + collectionEntityId);
  }
  GetSubTasks(taskId, entityId) {
    return this.http.get(`${Config.apiUrl}/tasks/GetSubTasks/` + taskId + '?entityId=' + entityId);
  }
  GetDashboard(data) {
    return this.http.post(`${Config.apiUrl}/tasks/GetDashboard`, data);
  }
  UpdateDashboard(data) {
    return this.http.post(`${Config.apiUrl}/tasks/UpdateDashboard`, data);
  }
  DeleteDashboardColumn(columnId, dashboardId, taskType) {
    // tslint:disable-next-line: max-line-length
    return this.http.get(`${Config.apiUrl}/tasks/DeleteDashboardColumn?columnId=` + columnId + `&dashboardId=` + dashboardId + `&taskType=` + taskType);
  }

  GetGantChartTasks(EntityId) {
    return this.http.get(`${Config.apiUrl}/Dashboard/GetGantChartTasks`, { params: { EntityId } });
  }
}
