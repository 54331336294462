import { MeetingGroupDetails } from './MeetingGroupDetails';
import { MeetingType } from './MeetingType';

export class Meeting {
    meetingId: number;
    meetingInviteeId: number;
    meetingTitle: string;
    roomName: string;
    meetingReferenceNumber: number;
    initiatedDate: string;
    startDate: string;
    startTime: string;
    jitsiServerUrl: string;
    endTime: string;
    endDateTime: string;
    isRejectedMeeting: boolean;
    rejectedDate: null;
    hasMom: boolean;
    meetingType: MeetingType;
    actualEndTime?: string;
    setMeetingPassword?: boolean;
    meetingStatus: {
        meetingType: MeetingType,
        statusText: string
    };
    groupDetails: MeetingGroupDetails = new MeetingGroupDetails();
    isOwner: boolean;
    ownerDetails: {
        email: string,
        fullName: string,
        profilePhoto: string,
        userId: string,
        userName: string,
    };
    meetingInviteePermissions: {
        canAccept: boolean,
        canAcceptAndJoin: boolean,
        canDecline: boolean,
        canDelegate: boolean,
        canWithdrow: boolean,
        joinNow: boolean,
        reAcceptMeetingInvitaion: boolean,
        isOwnerJoined: boolean

    };
    meetingPermissions: {
        canCancelMeeting: boolean,
        canHoldMeeting: boolean,
        canCreateMom: boolean,
        canDeleteMeeting: boolean,
        canEditMeeting: boolean,
        canEndMeeting: boolean,
        canGoLive: boolean,
        canViewMOM?: boolean
    };
}
