import { Collection } from './Collection';
import { ForwardTaskUserModel } from 'src/app/Models/tasks/ForwardTaskUserModel';

export class Task {
    taskId: number;
    taskTitle: string;
    taskDetails: string;
    isPrivate: boolean;
    taskPriorityId: number;
    taskPriorityName: string;
    doneSubTaskCount: number;
    subTaskCount: number;
    parentTaskId: number;
    parentTask = {} as this;
    subTasks: [];
    requestedDate: '';
    startDate: '';
    dueDate: '';
    maximumDueDate: Date;
    minimumDueDate: Date;
    completion?: number;
    created: Date;
    isAssignedToMe: boolean;
    creatorData: {
        userImage: string;
        userTitle: string;
    };
    groupData = {} as {
        groupId: number,
        groupName: string,
        groupAbbreviation: string
    };
    userData: object;
    taskAttachments: [];
    collectionData = {} as Collection;
    canViewSubTasks: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canViewTaskStatusBtn: boolean;
    canViewMarkAsDoneBtn: boolean;
    showReviewTask: boolean;
    canEditOutputs: boolean;
    showTaskOutputs: boolean;
    isTaskCreator: boolean;
    isRequestNewDate: boolean;
    isFollower: boolean;
    taskStatusName: string;
    taskStatusId: number;
    taskStatuses = [];
    forwardUserData: ForwardTaskUserModel;
    canAddSubTask: boolean;
    canForwardSubTask: boolean;
    commentsCount?: number;
    attachmentsCount?: number;
    subTasksDoneCount?: number;
    canEditcompletion?: boolean;
}
