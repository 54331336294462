import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html'
})
export class FileManagerComponent implements OnInit {
  @Output() selectedFiles = new EventEmitter<[]>();
  @Output() deletedFilesList = new EventEmitter<[]>();
  @Input() isRequired;
  @Input() isSubmited;
  // DnD setting
  @Input() files: any = [];
  @Input() docsEditable = true;
  @Input() maxFiles = 5;
  @Input() maxFileSize = 5;
  @Input() managerIndex = 0;
  selectedfilesCount = 0;
  deletedFiles: any = [];
  validFile = true;
  fileMaxSiZeErorr = false;

  constructor() { }

  ngOnInit() {
  }
  fileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      case 'doc':
      case 'docx':
      case 'wpd':
      case 'tex':
        return 'doc';
      case 'xlsx':
      case 'xls':
        return 'xls';
      case 'zip':
      case 'rar':
        return 'zip';
      default:
        return false;
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event) {
    this.prepareFilesList(event.target.files);
    event.target.value = '';
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  // deleteFile(index: number) {
  //   this.files.splice(index, 1);
  //   this.selectedfilesCount = this.files.length;
  //   this.selectedFiles.emit(this.files);
  // }

  deleteFile(index: number, id) {
    if (id) {
      this.deletedFiles.push(id);
      console.log(this.deletedFiles);
    }
    this.files.splice(index, 1);
    this.selectedfilesCount = this.files.length;
    this.selectedFiles.emit(this.files);
    this.deletedFilesList.emit(this.deletedFiles);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    this.validFile = true;
    this.selectedfilesCount = (this.files.length + files.length);
    if (this.selectedfilesCount > this.maxFiles) {
      return;
    }
    for (const item of files) {
      if (this.fileType(item.name)) {
        this.validFile = true;
      } else {
        this.validFile = false;
      }

      // Check file and push to array files
      if (((item.size / (1024 * 1024)) <= this.maxFileSize) && (this.fileType(item.name))) {
        item.fileType = this.fileType(item.name);
        this.files.push(item);
        this.selectedFiles.emit(this.files);
        this.fileMaxSiZeErorr = false;
      } else {
        this.fileMaxSiZeErorr = true;
      }
    }
    console.log('files', this.files);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
