import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { SidebarService } from '../services/sidebar.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private sidebarService: SidebarService,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
          // const menuList = JSON.parse(localStorage.getItem('menu')) || [];
          this.sidebarService.menuLoaded.subscribe(loaded => {
            if (loaded) {
              if (location.pathname !== '/') {
                let menuItems = [];
                let canAccess = false;
                this.authenticationService.menuItems.subscribe(data => {
                  menuItems = [...data];
                  menuItems.push('/login');
                  menuItems.push('/logout');
                  menuItems.push('/admin/myprofile');
                  menuItems.push('/admin/changepassword');
                  menuItems.push('/admin/livemeeting');
                  menuItems.forEach(item => {
                    if (location.href.includes(item)) {
                      canAccess = true;
                    }
                  });
                  if (canAccess) {
                    return true;
                  }
                  this.router.navigate(['/admin/myprofile']);
                });
              }
            }
          });
          // authorised so return true
          return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }

    canAccess() {

    }
}
