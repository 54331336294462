import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  menuItems: any;
  menuLoaded = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private sidebarService: SidebarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sidebarService.menuLoaded.subscribe(data => {
      if (data) {
        this.menuLoaded = true;
      }
    });
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root));
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string= '', breadcrumbs: any = []): [] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[MainLayoutComponent.ROUTE_DATA_BREADCRUMB];
      let title = '';
      if (child.snapshot.data.title) {
        title = child.snapshot.data.title;
      }
      if (label) {
        if (!isNullOrUndefined(label)) {
          breadcrumbs.push({label, url, title});
        }
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
