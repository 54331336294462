import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgendaItem } from 'src/app/Models/meeting/AgendaItem';
import { Meeting } from 'src/app/Models/meeting/Meeting';
import { MeetingDetailsService } from 'src/app/services/meeting-details.service';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-agenda-item',
  templateUrl: './agenda-item.component.html',
  styles: []
})
export class AgendaItemComponent implements OnInit {
  language = localStorage.getItem('language') || 'en';
  @Output() cancelPop: EventEmitter<any> = new EventEmitter();
  @Input() item: AgendaItem;
  @Input() agendaItemsCount: number;
  @Input() meeting: Meeting;
  @Input() liveView: boolean;
  itemOrder: number;
  itemsCount: number;
  loadingData = false;
  openEditModal = false;
  commentsLoader = false;

  terms = [];
  listItem: string;
  itemLoading = false;
  recommendsLoader = false;
  recommendsDeleteId: number;
  recommendsResult: any;
  // allowVote =  false;
  allowVote = true; // comment

  openTaskForm = false;
  taskTitle: string;

  commentsResult: any;

  selectedIndex;

  isShow = false;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private signalRService: SignalRServiceService,
    private detailsService: MeetingDetailsService) {
      this.signalRService.buildConnection();
      this.detailsService.refreshAgendaItemSubject.subscribe(data => {
        if (data) {
          this.getAgendItemDetails();
        }
      });
    }

  ngOnInit() {
    console.log(this.item);
    this.itemsCount = this.item.itemsCount;
    this.getAgendItemDetails();
    this.listAgendItemComments();
    this.listAgendItemRecommendations();
    this.signalRService.handleLoadMessageCommentsEvent(this, this.loadMessageComments);

    // startVoting
    this.detailsService.startVoting.subscribe(data => {
      if (data) {
        this.isShow = true;
        this.selectedIndex = 2;
      }else{
        this.isShow = false;
      }
    });
  }

  loadMessageComments(context, messageId) {
    if (messageId === context.item.agendaItemEntityId) {
      context.listAgendItemComments();
      context.changeDetectorRef.detectChanges();
    }
  }
  getAgendItemDetails() {
    this.loadingData = true;
    if(this.item && this.item.agendaItemEntityId){
      this.detailsService.GetAgendItemDetails(this.item.agendaItemEntityId).subscribe(
        data => {
          this.loadingData = false;
          this.item = data as AgendaItem;
          this.item.itemsCount = this.itemsCount;
        }, err => {
          console.error(err);
        });
    }

  }
  listAgendItemComments() {
    this.commentsLoader = true;
    if(this.item && this.item.agendaItemEntityId){
      this.detailsService.ListAgendItemComments(this.item.agendaItemEntityId).subscribe(
        data => {
          this.commentsLoader = false;
          this.commentsResult = data;
        }, err => {
          console.error(err);
        });
    }

  }
  DeleteAgendaItem() {
    if ( this.agendaItemsCount <= 1 ) {
      Swal.fire({
        text:   this.translate.instant('swalMsg.YouAgendaItem'),
        icon:  'warning',
        confirmButtonText:  this.translate.instant('swalMsg.ok'),
      });
    } else {
      Swal.fire({
        title: this.translate.instant('swalMsg.Areyousure'),
        text: this.translate.instant('swalMsg.Deletethisagendaitem'),
        icon:   'warning',
        showCancelButton: true,
        confirmButtonText:   this.translate.instant('swalMsg.Yesdeleteit'),
        cancelButtonText:  this.translate.instant('swalMsg.Nokeepit')
      }).then((result) => {
        if (result.value) {
          this.detailsService.DeleteMeetingAgendaItem(this.item.agendaItemEntityId).subscribe(
            data => {
              Swal.fire({
                title: this.translate.instant('swalMsg.deletededsuccessfuly'),
                // text:  this.translate.instant('swalMsg.deletededsuccessfuly'),
                icon:   'success',
                confirmButtonText:  this.translate.instant('swalMsg.ok'),
              }).then(() => {
                this.closePop();
                this.detailsService.refreshDetails.next(true);
              });
            },
            error => {
              console.log('Try again!, ' + error);
            });
        }
      });
    }
  }
  closePop() {
    this.cancelPop.emit();
  }
  saveItemSucsses() {
    this.openEditModal = false;
    Swal.fire({
      title: this.translate.instant('swalMsg.success'),
      text: this.translate.instant('swalMsg.updatedsuccessfully'),
      icon:  'success',
      confirmButtonText:  this.translate.instant('swalMsg.ok'),
    }).then((result) => {
      this.getAgendItemDetails();
    });
  }

  // recommendition tab
  listAgendItemRecommendations() {
    this.recommendsLoader = true;
    this.detailsService.ListAgendItemRecommendations(this.item.agendaItemEntityId).subscribe(
      data => {
        this.recommendsLoader = false;
        this.terms = data as [];
        console.log(this.terms);

      }, err => {
        console.error(err);
      });
  }
  addListItem(event) {
    event.preventDefault();
    // if (this.terms.length < 5) {
    if (this.listItem) {
      const obj = {
        AgendaItemEntityId: this.item.agendaItemEntityId,
        RecommendationText: this.listItem
      };
      this.itemLoading = true;
      this.detailsService.AddRecommendation(obj).subscribe(
        data => {
          this.itemLoading = false;
          // this.terms.push({ title: this.listItem });
          this.listAgendItemRecommendations();
          this.listItem = undefined;
        },
        error => {
          console.log('Try again!, ' + error);
        });
    }
    // }
  }
  removeListItem(index, id) {
    this.recommendsDeleteId = id;
    // this.detailsService.refreshAgendaItemSubject.next(true);
    this.detailsService.DeleteRecommendation(id).subscribe(
      data => {
        this.recommendsDeleteId = undefined;
        this.listAgendItemRecommendations();
        // this.terms.splice(index, 1);
      },
      error => {
        console.log('Try again!, ' + error);
      });
  }
  updateListItem(index, event) {
    this.terms[index].title = event.target.value;
  }
  openCreateTask(title) {
    this.openTaskForm = true;
    this.taskTitle = title;
  }
  // openCreateTask($event) {
  //   this.openTaskForm = $event.open;
  //   this.taskTitle = $event.title;
  // }
  // openCreateTask(title) {
  //   this.openTaskForm.emit({open: true, title});
  // }
  textAreaAdjust(event) {
    event.target.style.height = '22px';
    event.target.style.height = event.target.scrollHeight + 'px';
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

}
