import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/models/config';
import { MessagesFilterModel } from 'src/app/models/messages/MessagesFilterModel';
import { CommentsFilterModel } from 'src/app/models/messages/CommentsFilterModel';
import { BehaviorSubject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class MessagesService {
     refreshDetails = new BehaviorSubject<boolean>(false);
     refreshListDetails = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) {
    }

    GetMessages(filterModel: MessagesFilterModel) {
        return this.http.post(`${Config.apiUrl}/messages/Messages`, filterModel);
    }
    GetGroups(compose) {
        return this.http.post(`${Config.apiUrl}/messages/GetGroups`, {CreateMessage: compose});
    }
    GetUsers() {
        // return this.http.get(`${Config.apiUrl}/messages/GetUsersInSameGroupAndEntity`);
        return this.http.get(`${Config.apiUrl}/messages/IndividualMessageUsers`);
    }
    PinMessage(messageId) {
        return this.http.get(`${Config.apiUrl}/messages/PinMessage/` + messageId);
    }

    NotifyMessage(obj) {
      return this.http.post(`${Config.apiUrl}/messages/NotifyMessageUser/`, obj);
    }

    GetGroupUsers(groupId) {
        return this.http.post(`${Config.apiUrl}/messages/GetUsers/`, {groupId});
    }
    GetGroupCollections(groupId, toReceivers: boolean) {
        const data = {GroupId : groupId, ToReceivers : toReceivers};
        return this.http.post(`${Config.apiUrl}/messages/GetCollections`, data);
    }
    GetCollectionsAndIndividual(groupId, toReceivers: boolean): any {
        const data = {GroupId : groupId, ToReceivers : toReceivers};
        return this.http.post(`${Config.apiUrl}/messages/GetCollectionsAndIndividual`, data);
    }
    CreateMessage(message, files: any, messageId) {
        let createApiUrl = '/messages/CreateMessage';
        if (messageId !== -1 && messageId !== undefined) { // send draft message service
            createApiUrl = '/messages/EditMessage';
        }

        const formData = new FormData();
        files.forEach(file => {
            if (file.attachmentId === undefined) { // send new files
                formData.append('file[]', file, file.name);
            }
        });
        formData.append('data', JSON.stringify(message));
        return this.http.post(`${Config.apiUrl}` + createApiUrl, formData);
    }
    DeleteMessage(messageId) {
        return this.http.get(`${Config.apiUrl}/messages/DeleteMessage/` + messageId);
    }
    ShareMessage(data) {
        return this.http.post(`${Config.apiUrl}/messages/ShareMessage`, data);
    }
    GetComments(filterModel: CommentsFilterModel) {
        return this.http.post(`${Config.apiUrl}/comments/GetComments/`, filterModel);
    }
    AddComment(commentobj) {
        return this.http.post(`${Config.apiUrl}/comments/AddComment`, commentobj);
    }
    EditComment(commentobj) {
        return this.http.post(`${Config.apiUrl}/comments/EditComment`, commentobj);
    }
    EditReply(commentobj) {
        commentobj.ObjectType = 'MessageEntity';
        return this.http.post(`${Config.apiUrl}/comments/EditReply`, commentobj);
    }
    GetCollections(messageId) {
        return this.http.post(`${Config.apiUrl}/messages/GetCollections`, {messageId});
    }
    GetReplies(GetReplyObj) {
        GetReplyObj.ObjectType = 'MessageEntity';
        return this.http.post(`${Config.apiUrl}/comments/GetReplies`, GetReplyObj);
    }
    AddReply(commentobj) {
        commentobj.ObjectType = 'MessageEntity';
        return this.http.post(`${Config.apiUrl}/comments/AddReply`, commentobj);
    }
    GetUnseenMessagesCount() {
        return this.http.get(`${Config.apiUrl}/messages/GetUnseenMessagesCount`);
    }
    GetUsersToDelegate(messageId) {
        return this.http.get(`${Config.apiUrl}/messages/GetUsersToDelegate/` + messageId);
    }
    DelegateCommentAssignee(userCollectionGroupinMessageId) {
        return this.http.get(`${Config.apiUrl}/messages/DelegateCommentAssignee/` + userCollectionGroupinMessageId);
    }
    CommentOnMessageAsWho(messageId) {
        return this.http.get(`${Config.apiUrl}/messages/CommentOnMessageAsWho/` + messageId);
    }
}
