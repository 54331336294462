export class AgendaItem {
    title: string;
    agendaItemEntityId: number;
    allowedForComment: boolean;
    allowedVoting: boolean;
    attachments: [];
    attendeeVotingAnswer: string;
    attendeeVotingAnswers: [];
    canComment: boolean;
    canEdit: boolean;
    canViewSummary: boolean;
    canViewVotes: boolean;
    comments: {
        numberOfCommentsWithReplies: number,
        pageData: [],
        pageIndex: number,
        pageSize: number,
        totalCount: number
    };
    commentAsWho: {
        collectionEntityId: number,
        currentUser: boolean,
        email: string,
        fullName: string,
        groupUserCollectionId: number,
        collectionsGroupingId: number,
        profilePhoto: string,
        userId: string,
        userName: string,
    }[];
    voteAsWho: {
        currentUser: boolean,
        email: string,
        fullName: string,
        profilePhoto: string,
        userId: string,
        userName: string,
    }[];
    deletedAttachments: [];
    description: string;
    itemOrder: number;
    recommendationList: [];
    votingList: [];
    votingModel: {
        title: string,
        votingSchemaType: any,
        votingAnswerTime: number,
        votingOptions: string[],
        deletedAttachments: [],
        votingRemainingTime: number,
        votingHasTime: boolean
    };
    votingSummaryList: [];
    meetingId: number;
    itemsCount: number;
    showRecommendation: boolean;
    editRecommendation: boolean;
    votingClosed: boolean;
    canChangeVote: boolean;
}
